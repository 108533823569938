// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
//import Popper from "popper.js";
window.jQuery = $;
window.$ = $;

require("bootstrap");
//require("slick-carousel");

// Remove this demo code, that's only here to show how the .env file works!
// if (process.env["HELLO"]) {
//   console.log(`Hello ${process.env.HELLO}!`);
// }

$(document).ready(function(){
    //jQuery stuff goes in here.

    // $('.collapse-on-click').click(function(){
    //     //window.console.log(this);
    //     $(this).css('max-height', 0);
    // });
    jQuery.each($('h1.text-serif.tem26, .team-member h3, div.tem13 p'), function(){
        jQuery(this).html(jQuery(this).text().replace(new RegExp('(CIMA|CFP|PLANNER)®', 'g'), '$1<sup>®</sup>'));
    });
    
    //Only show the sizer on the test hosts
    var testHosts = ['greenwealthmanagement.test', 'localhost'];
    if (testHosts.indexOf(window.location.hostname) > -1) {
        $('#sizer').show();
    }

});
